import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { RdsDialogService, RdsToastService } from '@rds/angular-components';

import { OAuthService } from 'angular-oauth2-oidc';
import { UserService } from 'src/app/service/user.service';
import { authCodeFlowConfig } from 'src/app/utility/auth-flow';

import { DisclaimerDialogComponent } from '../start/disclaimer-dialog/disclaimer-dialog.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    private readonly rdsToastService = inject(RdsToastService);
    public error = false;
    public errorMessage = '';
    public initLoading = true;
    public loading = true;

    constructor(
        private oAuthService: OAuthService,
        private userIdentityService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dialogService: RdsDialogService
    ) {}

    public async login() {
        this.error = false;
        this.errorMessage = 'Unknown error';
        this.activatedRoute.queryParamMap.subscribe({
            next: (paramMap) => {
                this.oAuthService
                    .loadDiscoveryDocumentAndLogin({
                        customHashFragment: window.location.search,
                        ...(paramMap.has('redirect') && {
                            state: paramMap.get('redirect') as string,
                        }),
                    })
                    .then(async (status) => {
                        if (status) {
                            try {
                                await this.userIdentityService.establishSession();
                                setTimeout(() => {
                                    const redirect = decodeURIComponent(
                                        this.oAuthService.state as string
                                    );
                                    if (redirect.length > 0) {
                                        this.router.navigate([redirect], {
                                            replaceUrl: true,
                                        });
                                    } else {
                                        this.router.navigate(['/sil/start'], {
                                            replaceUrl: true,
                                        });
                                    }
                                }, 500);
                            } catch (ex: any) {
                                console.error(ex);
                                this.handleError(ex.message);
                            }
                        }
                    })
                    .catch((err) => {
                        this.error = true;
                        console.error(err);
                    });
            },
            error: (err) => {
                this.error = true;
                console.error(err);
                if (
                    !authCodeFlowConfig.issuer ||
                    !authCodeFlowConfig.clientId ||
                    !authCodeFlowConfig.dummyClientSecret ||
                    !authCodeFlowConfig.scope ||
                    !authCodeFlowConfig.redirectUri
                ) {
                    this.errorMessage =
                        'Critical configuration error. Login disabled';
                }
            },
        });
    }

    public loginRequest() {
        this.login();
    }

    ngOnInit(): void {
        if (
            window.location.search.indexOf('?code=') === 0 ||
            this.oAuthService.hasValidAccessToken()
        ) {
            this.login();
        } else {
            this.initLoading = false;
            this.loading = false;
        }

        if (sessionStorage.getItem('justExpired') === 'true') {
            this.rdsToastService.error(
                'Session expired',
                'Please log in again'
            );
            sessionStorage.setItem('justExpired', 'false');
        }
    }

    private handleError(text: string) {
        // this.messageService.showMessage(text, MESSAGE_TYPE.ERROR);
        this.error = true;
        this.loading = false;
        this.errorMessage = text;
    }
}
