import { AuthConfig } from 'angular-oauth2-oidc';

export const authCodeFlowConfig: AuthConfig = {
    // clientId: 'server.code',
    clientId: import.meta.env.NG_APP_OIDC_RP_CLIENT_ID,

    // URL of the SPA to redirect the user to after login
    dummyClientSecret: import.meta.env.NG_APP_OIDC_RP_CLIENT_SECRET,

    // The SPA's id. The SPA is registerd with this id at the auth-server
    // Url of the Identity Provider
    issuer: import.meta.env.NG_APP_OIDC_OP_AUTHORIZATION_ENDPOINT,
    redirectUri: import.meta.env.NG_APP_LOGIN_REDIRECT_URL,
    responseType: 'code',

    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    // The api scope is a usecase specific one
    scope: import.meta.env.NG_APP_OIDC_RP_SCOPES,

    showDebugInformation: true,
};
