import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';

import { UserCredentials, UserIdentity } from '../model/userIdentity.model';

@Injectable({ providedIn: 'root' })
export class UserService {
    public _identityEstablished$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    public _sessionExpiredCommand$: BehaviorSubject<null> =
        new BehaviorSubject<null>(null);
    public _userCredentials$: BehaviorSubject<UserCredentials | null> =
        new BehaviorSubject<UserCredentials | null>(null);
    public _userIdentity$: BehaviorSubject<UserIdentity | null> =
        new BehaviorSubject<UserIdentity | null>(null);

    public identityEstablished = this._identityEstablished$.asObservable();
    public sessionExpiredCommand = this._sessionExpiredCommand$.asObservable();
    public userCredentials = this._userCredentials$.asObservable();
    public userIdentity = this._userIdentity$.asObservable();

    constructor(
        private oAuthService: OAuthService,
        private router: Router
    ) {}

    public establishSession(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.oAuthService.hasValidAccessToken()) {
                const interval = setInterval(() => {
                    if (!this.oAuthService.hasValidAccessToken()) {
                        clearInterval(interval);
                        this.sessionExpiredCommandRequest();
                    }
                }, 5000);
                try {
                    this.instantiate(
                        this.oAuthService.getAccessToken(),
                        this.oAuthService.getIdToken(),
                        this.oAuthService.getAccessTokenExpiration()
                    );
                    resolve(true);
                } catch (ex) {
                    reject(ex);
                }
            }
            reject(false);
        });
    }

    public instantiate(
        accessToken: string,
        idToken: string,
        expires: number
    ): void {
        const aToken = jwt_decode(accessToken) as any;
        const iToken = jwt_decode(idToken) as any;
        this._userIdentity$.next({
            email: aToken.email,
            givenName: aToken.givenName,
            lastName: aToken.lastname,
            name: aToken.name,
            sub: aToken.sub,
        });
        this._userCredentials$.next({
            accessToken: accessToken,
            clientId: iToken.aud,
            expiresIn: expires,
            idToken: idToken,
        });
        this._identityEstablished$.next(true);
    }

    public logout(expired = false): void {
        sessionStorage.clear();
        if (expired) {
            sessionStorage.setItem('justExpired', 'true');
        }
        this._identityEstablished$.next(false);
        this._userIdentity$.next(null);
        this._userCredentials$.next(null);
        this.router.navigate(['/']);
        setTimeout(() => {
            window.location.reload();
        }, 5);
    }

    public sessionExpiredCommandRequest() {
        this._sessionExpiredCommand$.next(null);
    }
}
