export const TermsOfServiceConfiguration = {
    forceReapprovalOnChange: true,
    listOfRules: [
        `The responsibility for appropriate reuse of information presented
            here remains with the user of the tool (DOP/SIL).`,
        `Data presented in the table are derived from Roche internal systems.
            Click the "i" icon to see the sources. If there is a discrepancy
            please inform us at dop.support@roche.com.`,
        `Approved protocols (RIM/eTMF) are continually being
            digitized(verbatim). If you need information that is not available
            in SIL, contact us at dop.support@roche.com.`,
    ],
};
