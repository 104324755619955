import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { UserIdentity } from 'src/app/model/userIdentity.model';
import { UserService } from 'src/app/service/user.service';

@UntilDestroy()
@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
    public activeRoute = '';
    public avatarInitials = '--';

    public gSiteLink = import.meta.env.NG_APP_CONTENT_GSITE_URL;
    public helpLink = import.meta.env.NG_APP_CONTENT_HELP_URL;
    public version = import.meta.env.NG_APP_HASH;

    constructor(
        private userService: UserService,
        public router: Router
    ) {}

    public externalLinkClick(link: string) {
        window.open(link, '_blank');
    }
    public logout(): void {
        this.userService.logout();
    }

    public nav($event: any): void {
        this.router.navigate([$event]);
    }
    public ngOnInit(): void {
        this.userService.userIdentity.pipe(untilDestroyed(this)).subscribe({
            next: (data) => {
                if (data) {
                    this.parseUserData(data);
                }
            },
        });
        this.router.events
            .pipe(untilDestroyed(this))
            .pipe(filter((e) => e instanceof NavigationEnd))
            .subscribe({
                next: (e) => {
                    const _e = e as NavigationEnd;
                    this.activeRoute = _e.url;
                },
            });

        this.activeRoute = this.router.url;
    }

    public onLogoTriggered(): void {
        this.router.navigate(['/sil/start']);
    }

    private parseUserData(data: UserIdentity) {
        this.avatarInitials =
            data.givenName.slice(0, 1) + '' + data.lastName.slice(0, 1);
    }
}
